import React from 'react';

const ProjectZero = () => {
    return (
        <div className="text-5xl pt-20 pl-32">
            <>WEB3d</>
        </div>
    );
};

export default ProjectZero;